import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import LottieLoader from 'react-lottie-loader';
import sunLoader from '../assets/anim/sun-loader.json'

import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useState, useEffect } from 'react';
import { useSendMessage } from '../hooks/customHooks';
import { useTranslation } from 'react-i18next';

const MessageForm = (props) => {
    const { t } = useTranslation();
    const [messageText, setMessageText] = useState("")
    const [emailText, setEmailText] = useState("")
    const [googleVerified, setGoogleVerifed] = useState(false)
    const { sendMessage, response, error, isLoading } = useSendMessage();

    useEffect(() => {
        setMessageText(props.message)

    }, [props.message])

    return (
        <section className='formSection' id='contact'>
            <p className='questionForm'>{t("titleQuestionForm")} </p>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { my: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField id="messageText"
                    sx={{ '& label': { color: '#1976d2' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'rgba(25, 118, 210, 0.5)' }, '&:hover fieldset': { borderColor: '#1976d2' } } }}
                    label={t("placeholderMessage")}
                    variant="outlined"
                    multiline rows={5}
                    inputProps={{
                        maxLength: 1000,
                    }}
                    value={messageText}
                    onChange={(value) => setMessageText(value.target.value)}
                />
                <TextField id="emailText"
                    sx={{ '& label': { color: '#1976d2' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'rgba(25, 118, 210, 0.5)' }, '&:hover fieldset': { borderColor: '#1976d2' } } }}
                    label={t("placeholderEmail")}
                    variant="outlined"
                    inputProps={{
                        maxLength: 100,
                    }}
                    value={emailText}
                    onChange={(value) => setEmailText(value.target.value)}
                />
            </Box>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>
                <GoogleReCaptcha onVerify={() => setGoogleVerifed(true)} />
            </GoogleReCaptchaProvider>
            {!!error && <Alert severity="error" variant="filled" sx={{ whiteSpace: 'pre-line' }}>{error}</Alert>}
            {isLoading && <LottieLoader animationData={sunLoader} className='loader' />}
            {!!response && <Alert severity="success" variant="filled" sx={{ mb: 1 }}>{t("alertSentMessage")} </Alert>}
            <Button variant="outlined" disabled={!googleVerified} onClick={() => sendMessage({ message: messageText, email: emailText })}/* { messageText.value.length < 10 && disabled } */>{t("btnSend")}</Button>

        </section>
    );
}


export default MessageForm
