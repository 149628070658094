import React from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const Footer = (props) => {
    const { t } = useTranslation();
    return (
        <footer>
            <Divider variant="middle" />
            <Box component="section" sx={{ p: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2} className='footerTitle'>
                        <Box sx={{ fontWeight: 500, fontSize: 20, m: 2 }}> FAQ </Box> <Box sx={{ fontWeight: 500, fontSize: 20, m: 2 }}> & </Box><Box sx={{ fontWeight: 500, fontSize: 20, m: 2 }}> {t('titleLinks')} </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box sx={{ width: '100%' }}>
                            <List sx={{ p: 0 }}>
                                <a href="#contact" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <ListItem disablePadding onClick={() => props.setMessageValue(t("placeholderIbelieve"))}>
                                        <ListItemButton sx={{ p: 0 }}>
                                            <ListItemText primary={t("btnContribute")} sx={{ p: 0 }} />
                                        </ListItemButton>
                                    </ListItem>
                                </a>
                                <a href="#contact" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <ListItem disablePadding onClick={() => props.setMessageValue(t("placeholder4Adds"))} >
                                        <ListItemButton sx={{ p: 0 }}>
                                            <ListItemText primary={t('btn4Adds')} sx={{ p: 0 }} />
                                        </ListItemButton>
                                    </ListItem>
                                </a>
                                <ListItem disablePadding>
                                    <ListItemButton component="a" href="https://www.freeprivacypolicy.com/live/34acc3f2-5505-4cf2-a820-27946bf2972a" target='_blank' sx={{ p: 0 }}>
                                        <ListItemText primary={t("btnLegacy")} sx={{ p: 0 }} />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton component="a" href="https://paypal.me/HappySunnyHour?country.x=BE&locale.x=fr_FR" target='_blank' sx={{ p: 0 }}>
                                        <ListItemText primary={t("btnDonation")} sx={{ p: 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5} margin="auto">
                        <Box sx={{ my: "auto" }}>
                            <Link href="https://www.linkedin.com/company/104196064" target="_blank"><LinkedIn sx={{ fontSize: 60, color: "#FFF", mx: 2 }} /></Link>
                            <Link href="https://www.facebook.com/profile.php?id=61561110657779" target="_blank"><Facebook sx={{ fontSize: 60, color: "#FFF", mx: 2 }} /></Link>
                            <Link href="https://www.instagram.com/happysunnyhour/" target="_blank"><Instagram sx={{ fontSize: 60, color: "#FFF", mx: 2 }} /></Link>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

        </footer>


    );
}

export default Footer;