import "../src/services/lang/i18next";
import HomeScreen from "./pages/HomeScreen";
const App = () => {
    return (
        <div className="App">
            <HomeScreen />
        </div>
    );
}

export default App;
