import * as React from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const Owners = () => {
    const { t } = useTranslation();
    return (
        <section className='ownerSection' id='owners'>
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
                {t("titleOwners")}
            </Typography>
            <Typography variant="h5" component="h2">
                {t("titleWhyBar")}
            </Typography>
            <Typography sx={{ m: 2 }}>
                <Typography component={'span'}>{t("pWhyBar")}</Typography>
            </Typography>
            <Typography id="modal-modal-title" variant="h5" component="h2">
                {t("titleOwnInfos")}
            </Typography>
            <Typography sx={{ m: 2 }}>
                <Typography component={'span'}>{t("pOwnInfos")} </Typography>
                <Typography component={'span'}>{t("pOwnInfos2")} </Typography>
            </Typography>
            <Typography variant="h5" component="h2">
                {t("titleAdvantages")}
            </Typography>
            <Typography sx={{ m: 2 }}>
                <Typography component={'span'}>{t("pAdvantages")} </Typography>
                <Typography component={'span'}>{t("pAdvantages2")} </Typography>

            </Typography>
        </section>
    );
}


export default Owners
