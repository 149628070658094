import React from 'react';
import logoletters from '../assets/img/logohshletters.png';
import logo from '../assets/img/logo.png';
import MenuBtn from './MenuBtn';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Translate from '@mui/icons-material/Translate';
import lang from '../services/lang/languages'
import Divider from '@mui/material/Divider';
import i18next from '../services/lang/i18next';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const pickLang = (lng) => {
        i18next.changeLanguage(lng);
    };
    return (
        <header>

            <img className="logo" src={logo} alt="HSH" />
            <img className="menulogo" src={logoletters} alt="HSH" />
            <div className='menu'>
                {/* <MenuBtn title={t('menuGestionbar')} link={"#owners"} />
                <MenuBtn title={t("menuContact")} link={"#contact"} /> */}
                {/* <MenuBtn title={"Login"} link={""} /> */}
                <Button
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{ p: 2, fontSize: 20, fontWeight: "bold", color: "#1f64ff" }}
                >
                    <Translate sx={{ fontSize: 30 }} />{/*  {t('menuLang')} */}
                </Button>
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    {lang.map((item) => {
                        return <MenuItem key={item.id} onClick={() => { pickLang(item.id); handleClose() }} sx={{ borderBottom: 1, borderColor: "#eee", '&:nth-last-child(1)': { borderBottom: 'none' } }}><img src={`https://flagpedia.net/data/flags/normal/${item.id}.png`} alt={item.id} className='flags' /> {item.nativeName}<Divider variant="middle" component="li" /></MenuItem>
                    })}
                </Menu>
            </div>
        </header>
    );
}

export default Header;