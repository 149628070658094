import p1 from '../assets/img/p1.png'
import p2 from '../assets/img/p2.png'
import p3 from '../assets/img/p3.png'
import p4 from '../assets/img/p4.png'
import p5 from '../assets/img/p5.png'
import p6 from '../assets/img/p6.png'
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

//import './styles.css';

// import required modules
import { EffectFade, Navigation, Pagination } from 'swiper/modules';

const AsideScreens = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                effect={'fade'}
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                modules={[EffectFade, Navigation, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={p1} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={p2} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={p3} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={p4} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={p5} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={p6} />
                </SwiperSlide>
            </Swiper>
        </>
    );
}

export default AsideScreens;
