import React from 'react';
import AsideScreens from './AsideScreens';
import imgDLPlaystore from '../assets/img/dl_on_playstore.png'
import imgDLApplestore from '../assets/img/dl_on_appstore.png'
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import hh from '../assets/img/marker_HH.png'
import hsh from '../assets/img/marker_HSH.png'
import sh from '../assets/img/marker_SH.png'
import event from '../assets/img/marker_event.png'
import terrace from '../assets/img/marker_terrace.png'

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Main = () => {
    const { t } = useTranslation();
    return (
        <section >
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <div className='intro'>
                        <h1>HappySunnyHour</h1>
                        <h3>{t("titleDesciption")}</h3>

                        <Box sx={{ width: '100%', maxWidth: 360, margin: 'auto' }}>
                            <List >
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <img src={hh} alt="" />
                                        </ListItemIcon>
                                        <ListItemText primary={t("titleHH")} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <img src={terrace} alt="" />
                                        </ListItemIcon>
                                        <ListItemText primary={t("titleTerraces")} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <img src={sh} alt="" />
                                        </ListItemIcon>
                                        <ListItemText primary={t("titleSH")} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <img src={hsh} alt="" />
                                        </ListItemIcon>
                                        <ListItemText primary={t("titleHSH")} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <img src={event} alt="" />
                                        </ListItemIcon>
                                        <ListItemText primary={t("titleEvent")} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>
                        <h3>{t("titleFullFree")}</h3>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <a href="https://play.google.com/store/apps/details?id=com.happysunnyhourfront&fbclid=IwZXh0bgNhZW0CMTAAAR0KfwXUxYx8UACF5D_JfjdyJ4mcNnOg1ssy0C0d0R2PPgFLH1d02ko29YU_aem_AYoZA2LNLRYXet1VFXQsPN6R-X_fcGUx70wCDDGMzgOpwYWfT1GhZxmYCw_ZIxa83ztBVb7h-o-E5Rop50eMskwJ&pli=1" target="_blank" rel="noopener noreferrer"><img src={imgDLPlaystore} alt="dl from playstore" className='dlImg' /></a>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <a href="https://apps.apple.com/be/app/happysunnyhour/id6473174441" target="_blank" rel="noopener noreferrer"><img src={imgDLApplestore} alt="dl from applestore" className='dlImg' /></a>

                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 2 }} >
                            <Typography>
                                {t("pMainDescription")}
                            </Typography>
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={12} md={5} >
                    <div className='screens'>
                        <AsideScreens />
                    </div>
                </Grid>
            </Grid>
        </section>
    );
}

export default Main;