
import Footer from '../components/Footer';
import Header from '../components/Header';
import Main from './Main';
import MessageForm from './MessageForm';
import Owners from './Owners';
import { useState } from 'react';
const HomeScreen = () => {
    const [message, setMessage] = useState("")
    const setMessageValue = (message) => {
        console.log(message);
        setMessage(message)
    }
    return (
        <>
            <Header />
            <Main />
            <MessageForm message={message} />
            <Owners />
            <Footer setMessageValue={setMessageValue} />
        </>
    );
}

export default HomeScreen;
