import { useState } from 'react';
import axios from 'axios';

const isProd = true
var env = ""
if (isProd)
    env = "https://happysunnyhour.herokuapp.com"
else
    env = "http://10.0.2.2:8080"

function getFieldErrors(error) {
    var errors = "";
    console.log("ERRORS: " + JSON.stringify(error.response.data, null, 4));
    if (error.response.data?.fieldErrors) {
        for (const fields in error.response.data.fieldErrors) {
            error.response.data.fieldErrors[fields].map(item => (
                errors = errors.concat(item + "\n")
            ))
        }
    } else
        errors = error.response.data.message
    return errors
}
export const useSendMessage = () => {

    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);

    const sendMessage = (props) => {
        setLoading(true);
        setError(null);
        setResponse(null);
        console.log("infos: " + JSON.stringify(props, null, 4));
        axios.post(env + '/api/report/', props)
            .then(function (response) {
                console.log("response report: " + JSON.stringify(response.data));
                setResponse(response.data)
            })
            .catch(function (error) {
                console.log(JSON.stringify(error, null, 4));
                const errors = getFieldErrors(error)
                setError(errors);
            })
            .finally(function () {
                setLoading(false);
            });
    }
    return { sendMessage, response, error, isLoading };
}