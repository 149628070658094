import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './en.json';
import fr from './fr.json';
import nl from './nl.json';
import it from './it.json';
import es from './es.json';
import de from './de.json';

export const languageResources = {
    en: { translation: en },
    fr: { translation: fr },
    nl: { translation: nl },
    it: { translation: it },
    es: { translation: es },
    de: { translation: de },
}


i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        compatibilityJSON: 'v3',
        //lng: 'fr',
        fallbackLng: 'en',
        resources: languageResources,
        detection: {
            order: ['cookie', 'path', 'navigator', 'htmlTag'],
        },
    })
export default i18next;